body {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
}

/* SVG elements animations */
@-webkit-keyframes updown {
    from {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
      }
      to {
        -webkit-transform: translateY(5%);
                transform: translateY(5%);
      }
  }
@keyframes updown {
    from {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
      }
      to {
        -webkit-transform: translateY(5%);
                transform: translateY(5%);
      }
  }

@-webkit-keyframes zoom {
  from {
      -webkit-transform: translateX(-3%);
              transform: translateX(-3%);
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    to {
      -webkit-transform: translateY(3%);
              transform: translateY(3%);
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@keyframes zoom {
  from {
      -webkit-transform: translateX(-3%);
              transform: translateX(-3%);
      -webkit-transform: scale(0.8);
              transform: scale(0.8);
    }
    to {
      -webkit-transform: translateY(3%);
              transform: translateY(3%);
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}

@-webkit-keyframes opacity {
  from {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes opacity {
  from {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@-webkit-keyframes rocketFlame {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    fill: rgb(255, 225, 53);

  }
  to {
    fill: #e27611;
    -webkit-transform: translate(0, 1%);
            transform: translate(0, 1%);
  }
}

@keyframes rocketFlame {
  from {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    fill: rgb(255, 225, 53);

  }
  to {
    fill: #e27611;
    -webkit-transform: translate(0, 1%);
            transform: translate(0, 1%);
  }
}

@-webkit-keyframes rocketThrust {
  0% {
    fill: rgb(255, 225, 53);

  }
  80% {
    fill: #f36139;
  }

  100% {
    fill: #cc1818;
  }
}

@keyframes rocketThrust {
  0% {
    fill: rgb(255, 225, 53);

  }
  80% {
    fill: #f36139;
  }

  100% {
    fill: #cc1818;
  }
}

@-webkit-keyframes rocketFlying {
  0% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    opacity: 0;
  }
  90% {
    -webkit-transform: translateY(-1%);
            transform: translateY(-1%);
  }
  100%
  {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 1;
  }
}

@keyframes rocketFlying {
  0% {
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    opacity: 0;
  }
  90% {
    -webkit-transform: translateY(-1%);
            transform: translateY(-1%);
  }
  100%
  {
    -webkit-transform: translateY(-100%);
            transform: translateY(-100%);
    opacity: 1;
  }
}

@-webkit-keyframes plantMovement {
  from {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes plantMovement {
  from {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@-webkit-keyframes boxAnimation {
  from {
    -webkit-transform: translate(-1%, 2%);
            transform: translate(-1%, 2%);
  }
  to {
    -webkit-transform: translate(-8%,4%);
            transform: translate(-8%,4%);
  }
}

@keyframes boxAnimation {
  from {
    -webkit-transform: translate(-1%, 2%);
            transform: translate(-1%, 2%);
  }
  to {
    -webkit-transform: translate(-8%,4%);
            transform: translate(-8%,4%);
  }
}

@-webkit-keyframes barAnimation {
  from {
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
  }
  to {
    -webkit-transform: translate(-3%,0%);
            transform: translate(-3%,0%);
  }
}

@keyframes barAnimation {
  from {
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
  }
  to {
    -webkit-transform: translate(-3%,0%);
            transform: translate(-3%,0%);
  }
}

#plant {
  -webkit-animation: 2s ease-in-out infinite alternate plantMovement;
          animation: 2s ease-in-out infinite alternate plantMovement;
}

#bars {
  -webkit-animation: 2s ease-in-out infinite alternate barAnimation;
          animation: 2s ease-in-out infinite alternate barAnimation;
}

#rocket {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: 5s ease-in-out infinite rocketFlying;
          animation: 5s ease-in-out infinite rocketFlying;
}

#rocket > #flame_center {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: 0.1s ease-in-out infinite alternate rocketFlame;
          animation: 0.1s ease-in-out infinite alternate rocketFlame;
}

#rocket > #flame_left, #flame_right {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: 0.2s ease-out infinite alternate rocketThrust;
          animation: 0.2s ease-out infinite alternate rocketThrust;
}

#box {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: 2s ease-in-out infinite alternate boxAnimation;
          animation: 2s ease-in-out infinite alternate boxAnimation;
}

#zoom {
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-animation: 2s ease-in-out infinite alternate zoom;
          animation: 2s ease-in-out infinite alternate zoom;
}

#chat > #like, #smiley {
  -webkit-transform-origin: 50% 50% 0;
          transform-origin: 50% 50% 0;
  -webkit-transform-origin: initial;
          transform-origin: initial;
  -webkit-animation: 1.2s ease-in-out infinite alternate opacity;
          animation: 1.2s ease-in-out infinite alternate opacity;
}

#chat > #chat_red {
  -webkit-transform-origin: 50% 50% 0;
          transform-origin: 50% 50% 0;
  -webkit-transform-origin: initial;
          transform-origin: initial;
  -webkit-animation: 1.2s ease-in-out infinite alternate updown;
          animation: 1.2s ease-in-out infinite alternate updown;
}

#chat > #chat_green {
  -webkit-transform-origin: 50% 50% 0;
          transform-origin: 50% 50% 0;
  -webkit-transform-origin: initial;
          transform-origin: initial;
  -webkit-animation: 1.5s ease-in-out infinite alternate updown;
          animation: 1.5s ease-in-out infinite alternate updown;
}


