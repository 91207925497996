/* SVG elements animations */
@keyframes updown {
    from {
        transform: translate(0, 0);
      }
      to {
        transform: translateY(5%);
      }
  }

@keyframes zoom {
  from {
      transform: translateX(-3%);
      transform: scale(0.8);
    }
    to {
      transform: translateY(3%);
      transform: scale(1);
    }
}

@keyframes opacity {
  from {
      transform: translate(0, 0);
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

@keyframes rocketFlame {
  from {
    transform: translate(0, 0);
    fill: rgb(255, 225, 53);

  }
  to {
    fill: #e27611;
    transform: translate(0, 1%);
  }
}

@keyframes rocketThrust {
  0% {
    fill: rgb(255, 225, 53);

  }
  80% {
    fill: #f36139;
  }

  100% {
    fill: #cc1818;
  }
}

@keyframes rocketFlying {
  0% {
    transform: translate(0, 100%);
    opacity: 0;
  }
  90% {
    transform: translateY(-1%);
  }
  100%
  {
    transform: translateY(-100%);
    opacity: 1;
  }
}

@keyframes plantMovement {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

@keyframes boxAnimation {
  from {
    transform: translate(-1%, 2%);
  }
  to {
    transform: translate(-8%,4%);
  }
}

@keyframes barAnimation {
  from {
    transform: translate(0%, 0%);
  }
  to {
    transform: translate(-3%,0%);
  }
}

#plant {
  animation: 2s ease-in-out infinite alternate plantMovement;
}

#bars {
  animation: 2s ease-in-out infinite alternate barAnimation;
}

#rocket {
  transform-origin: center;
  animation: 5s ease-in-out infinite rocketFlying;
}

#rocket > #flame_center {
  transform-origin: center;
  animation: 0.1s ease-in-out infinite alternate rocketFlame;
}

#rocket > #flame_left, #flame_right {
  transform-origin: center;
  animation: 0.2s ease-out infinite alternate rocketThrust;
}

#box {
  transform-origin: center;
  animation: 2s ease-in-out infinite alternate boxAnimation;
}

#zoom {
  transform-origin: center;
  animation: 2s ease-in-out infinite alternate zoom;
}

#chat > #like, #smiley {
  transform-origin: initial;
  animation: 1.2s ease-in-out infinite alternate opacity;
}

#chat > #chat_red {
  transform-origin: initial;
  animation: 1.2s ease-in-out infinite alternate updown;
}

#chat > #chat_green {
  transform-origin: initial;
  animation: 1.5s ease-in-out infinite alternate updown;
}

